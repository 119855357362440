import React from 'react';
import Bolgcontent from "./bolgcontent";
import Recentpost from "./recentpost";
import Layout from "../layout/layout";

function Index({slug}) {
    return (
        <Layout>
            <Bolgcontent slug={slug}/>
            <Recentpost slug={slug}/>
        </Layout>
    );
}

export default Index;